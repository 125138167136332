import React from 'react';
import './ControlRoomBanner.css';
import { getMobileOperatingSystem } from '../helper/platformhelper';

import imgControlRoom from '../images/control_room.png';
import imgSubmitRefresh from '../images/submit_refresh.png';

class ControlRoomBanner extends React.Component{

  render(){

    const{submitListener} = this.props;

    return(
      <div className='bannerControlRoom'>
        <img id='controlRoomLogo' src={imgControlRoom} alt=''/>
        <img id='submitRefresh' onClick={submitListener} src={imgSubmitRefresh} alt=''/>
      </div>
    )
  }
}

export default ControlRoomBanner;
