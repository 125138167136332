import React, {Component} from 'react';
import Profile from './Profile';
import Timeline from './Timeline';
import Banner from './Banner';
import Spinner from './Spinner';
import axios from 'axios';
import {Helmet} from 'react-helmet';

import './App.css';

const base_url = '/api/';
const version = 'v1/';
const profile = 'users/get-profile/';
const timeline = 'users/get-timeline';
const favorite = 'users/get-favorite';

// const secret = 'secret=mingyuanwang-web-201711';

const applySetError = (prevState) => ({
  isError: true,
  isLoading: false,
});

const applyUpdateResult = (success, result, page, hasMore) => (prevState) => ({
  timeline:[...prevState.timeline, ...result],
  page: page,
  isError: false,
  isLoading: false,
  hasMore: hasMore,
  success: success,
});

const applySetResult = (success, result, page, hasMore) => (prevState) => ({
  timeline:result,
  page:page,
  isError: false,
  isLoading: false,
  hasMore: hasMore,
  success: success,
});

const handleStaredBattle = (result) => {
  if(result.length >= 4 && result[0].priority > 0 && result[1].priority > 0 && result[2].priority > 0 && result[3].priority > 0){

    if(result.length === 4){
      return result;
    }else {
      let first = result[0];
      let second = result[1];
      let third = result[2];
      let fourth = result[3];
      first.second = second.post.thumbnailUrl;
      first.third = third.post.thumbnailUrl;
      first.fourth = fourth.post.thumbnailUrl;
      first.isCompound = true;

      let length = result.length;

      let feeds = result.slice(4, length);

      return [first, ...feeds];
    }
  }else {

    return result;
  }
}

// const handleStartBattle = (result) => {
//   let feeds = new Array();
//   let threadIds = new Array();
//
//   result.forEach(feed => {
//     let threadId = feed.post.threadId;
//     if(feed.post.type === 'battleout'){
//       if(threadIds.indexOf(threadId) === -1){
//         threadIds.push(threadId);
//       }
//       feeds.push(feed);
//     }else {
//       if(threadIds.indexOf(threadId) === -1 || feed.battle.priority > 0){
//         feeds.push(feed);
//       }
//     }
//   });
//
//   return feeds;
// }

class User extends Component {

    constructor(prop) {
        super(prop);

        this.state = {
            user: null,
            timeline: null,
            perpage: 0,
            page: 1,
            isLoading: false,
            isError: false,
            hasMore: false,
            isTimeline: true,
            success: false,
            is404: false,
        };

        this.fetchProfile.bind(this);
        this.fetchTimeline.bind(this);
        this.setResult.bind(this);
    }

    setResult(data, is404) {
        this.setState({user: data.user, message: data.message, is404: is404});
    }

    setIsTimeline(isTimeline){
      this.setState({isTimeline,
        timeline: null,
        success: false});
      if(isTimeline){//set timeline data
        this.fetchTimeline(this.state.user.id, 1);
      }else {//set favorite data
        this.fetchFavorite(this.state.user.id, 1);
      }
    }

    fetchProfile(username) {
      const url = `${base_url}${version}${profile}${username}`;
      axios.get(url)
      .then(response => {
        this.setResult(response.data, false);
        this.fetchTimeline(response.data.user.id, 1);
      })
      .catch(error => {
        if(null !== error && 'undefined' !== typeof(error)  && null !== error.response && 'undefined' !== typeof(error.response)){
          if(404 === error.response.status){
            this.setState({is404: true});
          }
        }
      })
    };

    fetchTimeline(userId, page){
      this.setState({isLoading: true});

      const url = `${base_url}${version}${timeline}?searchUserId=${userId}&page=${page}`;

      fetch(url)
      .then(response => response.json())
      .then(result => {this.state.isTimeline && this.onSetResult(true, result.data, page, result.data !== null && (result.data.length === result.perPage))})
      .catch(this.onSetError);
    }

    fetchFavorite(userId, page){
      this.setState({isLoading: true});

      const url = `${base_url}${version}${favorite}?searchUserId=${userId}&page=${page}`;

      fetch(url)
      .then(response => response.json())
      .then(result => {!this.state.isTimeline && this.onSetResultFavorite(true, result.data, page, result.data !== null && (result.data.length === result.perPage))})
      .catch(this.onSetError);
    }

    onPaginatedSearch = () =>{
      if(this.state.isTimeline){
        this.fetchTimeline(this.state.user.id, this.state.page + 1);
      }else {
        this.fetchFavorite(this.state.user.id, this.state.page + 1);
      }
    }


    onSetError = () =>
      this.setState(applySetError);

    onSetResult = (success, result, page, hasMore) =>
      page === 1
        ? this.setState(applySetResult(success, result, page, hasMore))
        : this.setState(applyUpdateResult(success, result, page, hasMore));

    onSetResultFavorite = (success, result, page, hasMore) =>
      page === 1
        ? this.setState(applySetResult(success, result, page, hasMore))
        : this.setState(applyUpdateResult(success, result, page, hasMore));

    componentDidMount() {
      const userName = this.props.params.userName;
      this.fetchProfile(userName);
    }

    render() {
        const {is404, success, user, timeline, isError, isLoading, page, hasMore, isTimeline} = this.state;

        if(is404){
          return(
          <div id='div404'>
            <Banner/>
            <div className='span404'>Oops, Page Not</div>
            <div className='span404'>Found! 😑</div>
          </div>
          );
        }

        if(null == user){
          return(
            <div id='divSpinnerBio'>
              <Spinner/>
            </div>
          );
        }

        const TimelineComponent = timeline != null && timeline.length > 0 ?
            <Timeline
              user={user}
              timeline={timeline}
              isError={isError}
              isLoading={isLoading}
              page={page}
              hasMore={hasMore}
              onPaginatedSearch={this.onPaginatedSearch}
            />
          :
            <div className='divSpinnerTimeline'>
              {success ? <span id='emptySpan'>IT'S KINDA EMPTY...</span> : <Spinner/>}
            </div>

        return (
          <div className='container'>
            <Helmet>
              <meta charSet="utf-8" />
              <title>@{user.username} on Social Club</title>
              <meta property="og:url" content={user.profilePictureUrl} />
              <meta property="og:image" content={user.profilePictureUrl} />
              <meta property="og:title" content={`@${user.username} on Social Club`} />
              <meta property="og:site_name" content="Social Club" />
              <meta property="og:description" content={user.bio} />
              <meta property="og:type" content="profile" />
            </Helmet>
            <Banner/>
            <Profile user={user} isTimeline={isTimeline} setIsTimeline = {timeline => this.setIsTimeline(timeline)}/>
            {
              TimelineComponent
            }
          </div>
        );
    }
}

export default User;
