import React from 'react';
import './BattleVideo.css';
import {videoContainerStyle, videoContainerReactangleStyle} from './BattleVideoStyle';
import { cdnMaker } from '../helper/CDNHelper';
import ViewPagerPhoto from '../ViewPagerPhoto';

class BattleVideo extends React.Component{
  render(){
    const {post} = this.props;
    const {type, thumbnailUrl, ratio, contentUrls} = post;
    let cdnUrl = "ov" === type? cdnMaker(JSON.parse(contentUrls)[0]) : JSON.parse(contentUrls)[0];
    return(
      "ov" === type?
      <div>
        <video src={cdnUrl} type='video/mp4' controls loop webkit-playsinline='true' playsInline={true} poster={cdnMaker(thumbnailUrl)}
          style={ratio !== 1 ? videoContainerReactangleStyle : videoContainerStyle}>
        </video>
      </div>
      :
      <div>
        <ViewPagerPhoto contentUrls={contentUrls}/>
      </div>
    );
  }
}



export default BattleVideo;
