import React, {Component} from 'react';
import ControlCell from './ControlCell';
import Spinner from './Spinner';
import PopupLogIn from './PopupLogIn';
import PopupSubmit from './PopupSubmit';
import ControlRoomBanner from './ControlRoomBanner';
import axios from 'axios';
import {Helmet} from 'react-helmet';

// import './App.css';

const base_url = '/api/';
const version = 'v1/';
const timeline = 'posts/upcoming-posts-to-review';
const secret = 'secret=mingyuanwang-web-201711';
const review = 'posts/submit-reviewed-posts';
const logInUrl = 'moderators/login';

const applySetError = (prevState) => ({
  isError: true,
  isLoading: false,
});

const applyUpdateResult = (success, result, page, hasMore) => (prevState) => ({
  timeline:[...prevState.timeline, ...result],
  page: page,
  isError: false,
  isLoading: false,
  hasMore: hasMore,
  success: success,
});

const applySetResult = (success, result, page, hasMore, goodPosts) => (prevState) => ({
  timeline:result,
  page:page,
  isError: false,
  isLoading: false,
  hasMore: hasMore,
  success: success,
  goodPosts: goodPosts,
  badPosts: [],
});

class ControlRoom extends Component {

    constructor(prop) {
        super(prop);

        this.state = {
            timeline: null,
            perpage: 0,
            page: 1,
            isLoading: false,
            isError: false,
            hasMore: false,
            success: false,
            is404: false,
            showLogInPopup: true,
            isLogIn: false,
            message:null,
            showSubmittingPopup: false,
            goodPosts: [],
            badPosts: [],
            moderator: null,
            userName: null,
            password: null,
        };

        this.fetchPostCandidates.bind(this);
        this.setResult.bind(this);

        this.submitSelection.bind(this);
        this.loginClickListener.bind(this);
        this.selectCell.bind(this);
        this.getPostsId.bind(this);

        this.toggleSubmittingPopup.bind(this);
    }

    toggleSubmittingPopup = () => {
      this.setState({showSubmittingPopup: !this.state.showSubmittingPopup});
    }

    getPostsId(result){
      if(null == result){
        return null;
      }
      let idsArray = [];
      result.map((item, index) => {
        idsArray.push(item.id);
      });

      //console.log("idsArray: " + idsArray);
      return idsArray;
    }

    selectCell = (id, index) => {
      //console.log('Yes! id: ' + id + "  index: " + index);
      const {goodPosts, badPosts} = this.state;

      var indexGood = goodPosts.indexOf(id);
      var indexBad = badPosts.indexOf(id);

      if (indexGood !== -1) {//it's not selected before, now select it
        goodPosts.splice(indexGood, 1);//remove the id from good posts

        //console.log("remove id from the goodPosts: " + id);
        //check if the badPosts already has it
        if(indexBad == -1){
          badPosts.push(id);//if it's not added before, now add it

          //console.log("add id to the badPosts: " + id);
        }
      }else {//it's selected before
        goodPosts.push(id);//add it to the goodPosts

        //console.log("add id to the goodPosts: " + id);
        if(indexBad !== -1){//indeed, it exists in badPosts
          badPosts.splice(indexBad, 1);
          //console.log("remove id from the badPosts: " + id);
        }
      }

      //console.log("goodPosts: " + goodPosts + " \nbadPosts: " + badPosts);
      this.setState({goodPosts: goodPosts, badPosts: badPosts});
    }

    setResult(data, is404) {
        this.setState({message: data.message, is404: is404});
    }

    fetchPostCandidates(page){
      this.setState({isLoading: true});

      let moderator = this.state.moderator;
      const secret = 'moderatorId=' + moderator.id + '&token=' + moderator.token;
      const url = `${base_url}${version}${timeline}?${secret}`;

      //console.log('fetch posts url:' + url);
      fetch(url)
      .then(response => response.json())
      .then(result => {this.onSetResult(true, result, page, false)})
      .catch(this.onSetError);
    }


    loginClickListener = () => {

      this.setState({isLogIn: true});

      const url = `${base_url}${version}${logInUrl}`;

      //console.log('login url: ' + url);

      const {userName, password} = this.state;

      //console.log("userName: " + userName + " password: " + password);

      fetch(url, {
        method: 'POST',
        body: JSON.stringify({username: userName, password: password}),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      })
      .then(response => response.json())
      .then(json => {
        //console.log(json);
        var moderatorData = json.moderator;
        let message = json.message;
        //console.log("message: " + message);

        if(moderatorData){
          //console.log("moderator id: " + moderatorData.id +"  username: " + moderatorData.username + " token: " + moderatorData.token);

          this.setState({moderator: moderatorData, showLogInPopup: !this.state.showLogInPopup, isLogIn: false});

          this.fetchPostCandidates();
        }else {
          this.setState({isLogIn: false, message: message});
        }
      })
      .catch((error) => {
        this.setState({isLogIn:false, showLogInPopup:true, message:'Network error! Please try again!'});
        //console.log("log in error: " + error);
      })
    }

    submitSelection = () => {

      this.setState({showSubmittingPopup : true});

      const {goodPosts, badPosts} = this.state;
      let moderator = this.state.moderator;

      const secret = 'moderatorId=' + moderator.id + '&token=' + moderator.token;

      let moderatorId = "" + moderator.id;
      let token = "" + moderator.token;

      var jsonBody = JSON.stringify({goodPosts, badPosts, moderatorId, token});

      //console.log("result json: " + jsonBody);

      const url = `${base_url}${version}${review}`;

      //console.log('submit selection url: ' + url);

      fetch(url, {
        method: 'PUT',
        body: jsonBody,
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      })
      .then(response => response.json())
      .then(json => {
        //console.log(json);

        const message = "Mission accomplished. Sit tight, bruh!";
        if(json.message){
          if(json.message === message){
            this.setState(
              {showSubmittingPopup: false,
                goodPosts: [],
                badPosts: [],
                timeline: null}
              );
            this.fetchPostCandidates();
          }
        }

        this.setState({showSubmittingPopup : false});
      })
      .catch((error) => {
        //console.log("submit error: " + error);
        this.setState({showSubmittingPopup : false});
      })
    }

    onPaginatedSearch = () =>{
      this.fetchPostCandidates(this.state.page + 1);
    }

    onSetError = () =>
      this.setState(applySetError);

    onSetResult = (success, result, page, hasMore) =>{
      // page === 1
      //   ? this.setState(applySetResult(success, result, page, hasMore))
      //   : this.setState(applyUpdateResult(success, result, page, hasMore));

      let idsArray = this.getPostsId(result);
      this.setState(applySetResult(success, result, page, hasMore, idsArray))

        //console.log("on set result");
    }

    componentDidMount() {
      // this.fetchPostCandidates();
      //console.log("hello, fetch discover time line");
    }


    handleChangeUserName = (e) => {
      const target = e.target;
      const name = target.name;
      const value = target.value;

      this.setState({[name]: value});
      //console.log("name: " + name + " value: " + value);
    }

    handleChangePassword = (e) => {
      const target = e.target;
      const name = target.name;
      const value = target.value;

      this.setState({[name]: value});
      //console.log("name: " + name + " value: " + value);
    }

    render() {
      //console.log('I was triggered during render');
        const {is404, success, timeline, isError, isLoading, page, hasMore, showLogInPopup} = this.state;

        if(is404){
          return(
          <div id='div404'>
          <div className='span404'>Oops, Page Not</div>
          <div className='span404'>Found! 😑</div>
          </div>
          );
        }

        const CandidatesComponent = timeline != null && timeline.length > 0 ?

          <div>
            <ControlCell
              timeline={timeline}
              isError={isError}
              isLoading={isLoading}
              page={page}
              hasMore={hasMore}
              onPaginatedSearch={this.onPaginatedSearch}
              selectCell={this.selectCell}
              badPosts={this.state.badPosts}
            />
          </div>
          :
          <div className='divSpinnerTimeline'>
            {success && !isLoading? <span id='emptySpan'>IT'S KINDA EMPTY...</span> : (showLogInPopup ? null : <Spinner/>)}
          </div>


        const PopupLogInComponent = this.state.showLogInPopup ?
          <PopupLogIn toggleLogInPopup={this.loginClickListener}
          handleChangePassword={this.handleChangePassword}
          handleChangeUserName={this.handleChangeUserName}
          isLogIn={this.state.isLogIn}
          message={this.state.message}/>
          : null;


        const PopupSubmitComponent = this.state.showSubmittingPopup ?
          <PopupSubmit/> : null;

        const Banner = this.state.showLogInPopup || this.state.isLoading ? null: <ControlRoomBanner submitListener = {this.submitSelection}/>;

        return (
          <div className='container-ControlRoom'>

            <div>
              {Banner}
            </div>

            <div>
              {CandidatesComponent}
            </div>

            <div>
              {PopupLogInComponent}
            </div>

            <div>
              {PopupSubmitComponent}
            </div>
          </div>
        );
    }
}

export default ControlRoom;
