export const videoContainerStyle = {
  width: '100vw',
  height: '100vw',
  'maxWidth': '900px',
  'maxHeight': '900px',
  margin: 'auto',
}

export const videoContainerReactangleStyle = {
  width: '100vw',
  height: '125vw',
  'maxWidth': '900px',
  'maxHeight': '1125px',
  margin: 'auto',
}
