import React from 'react';
import './BattleFooter.css';
import btnVote from '../images/btn_vote.png';

class BattleFooter extends React.Component{

  render(){
    const {post} = this.props;
    if(null == post){
      return <div></div>;
    }

    const {user,caption} = post;

    return(
      <div id='containerFooter'>

        {
          caption &&
          <div id='caption1Container'>
            <span className='userNameCaption'>{user.username} </span>{caption}
          </div>
        }

      </div>
    );
  }
}

export default BattleFooter;
