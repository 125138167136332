import React from 'react';
import './Banner.css';
import { getMobileOperatingSystem } from '../helper/platformhelper';

const logoSocialClub = 'http://cdn.socialclub.life/icon/social-club-banner-logo.png';
const logoStoreApple = 'http://cdn.socialclub.life/icon/download-on-app-store.png';
const logoStoreGoogle = 'http://cdn.socialclub.life/icon/get-on-google-play.png';
const urlGoogle = 'https://onelink.to/socialclub';
const urlApple = 'https://apps.apple.com/us/app/social-club/id6444059570';


class Banner extends React.Component{

  render(){
    return(
      <div className='banner'>
        <div id='battleAppLogo'>
          <img id='imgLogo' src={logoSocialClub} alt=''/>
        </div>
        <div id='storeLogo'>
          <a href={getMobileOperatingSystem() === 'iOS' ? urlApple : urlGoogle}>
            <img id='imgStore' src={getMobileOperatingSystem() === 'iOS' ? logoStoreApple : logoStoreGoogle} alt=''/>
          </a>
        </div>
      </div>
    )
  }
}

export default Banner;
