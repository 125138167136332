import React from 'react';
import './PopupLogIn.css';
import Spinner from '../Spinner';

class PopupLogIn extends React.Component{
  render(){
    const {toggleLogInPopup, handleChangeUserName, handleChangePassword, isLogIn, message} = this.props;

    if(isLogIn){
      return(
        <div className='popup_outer'>
          <div className='popup_inner'>
            <div className='contentPopup'>
              <h1 align='center' id='titleLogin'>Control Room</h1>

              <div className='logInSpinner'>
                <Spinner/>
              </div>

            </div>
          </div>
        </div>
      );
    }else {
      return(
        <div className='popup_outer'>
          <div className='popup_inner'>
            <div className='contentPopup'>
              <h1 align='center' id='titleLogin'>Control Room</h1>

              <div className='loginField'>
                <input id='inputField'
                  type="text"
                  name="userName"
                  placeholder='User Name'
                  onChange={e => handleChangeUserName(e)}
                  className="form-control"
                />
              </div>

              <div className='loginField'>
                <input id='inputField'
                  type="password"
                  name="password"
                  placeholder='Password'
                  onChange={e => handleChangePassword(e)}
                  className="form-control"
                />
              </div>

              <div id='messageDiv'>
                <label id='message'>{message}</label>
              </div>

              <button id='SignInButtonPopup' onClick={toggleLogInPopup}>Sign In</button>

            </div>
          </div>
        </div>
      );
    }
  }
}



export default PopupLogIn;
