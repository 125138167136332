import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import { Router, Route, browserHistory } from 'react-router';
import User from './User';
import './index.css';
import Battle from './Battle';
import Story from './Story';
import StarredBattle from './StarredBattle';
import PageNotFound from './PageNotFound';
import ControlRoom from './ControlRoom';

ReactDOM.render(
  <Router history={browserHistory}>
    <Route exact path='/' component={()=> {window.location.href = 'https://onelink.to/socialclub'; return null}}/>
    <Route path="/:userName" component={User}/>
    <Route path="/user/:userName" component={User}/>
    <Route path="/u/:userName" component={User}/>
    <Route path='/p/:shareId' component={Battle}/>
    <Route path='/video/:shareId' component={Battle}/>
    <Route path='/s/:shareId' component={Story}/>
    <Route path='/:userName/starred' component={StarredBattle}/>
    <Route path='/control-room' component={ControlRoom}/>
    <Route path='*' component={PageNotFound}/>
  </Router>,
  document.getElementById('root'));

// Gotta disable assets serving from local cache...
// Otherwise, one "404 Not Found" route leads to many unknown weird "404 Not Found" ones,
// because of our configuration in handling the routes!
// registerServiceWorker();
