import React, {Component} from 'react';
import './App.css';
import Banner from './Banner';

class PageNotFound extends Component{
  render(){
    return(
      <div id='div404'>
      <Banner/>
      <div className='span404'>Oops, Page Not</div>
      <div className='span404'>Found! 😑</div>
    </div>
    )
  }
}

export default PageNotFound;
