const abbrArr = ['K', 'M', 'B', 'T'];
export const NumberFormatter = (number) => {
  for (let i = abbrArr.length; i >= 0; i--) {
    const compareNumber = Math.pow(10, (i + 1) * 3);
    if (number < 0) return 0; // number should always be larger than or equal to 0.
    if (number >= compareNumber) {
      const result = number / compareNumber;
      // check if result is negative, show 0 if it is negative
      if (result % 1 !== 0) {
        return `${result.toFixed(1)}${abbrArr[i]}`;
      }
      return result + abbrArr[i];
    }
  }
  return number;
};


export const percentage = (user1VotesCount, user2VotesCount) => {
  let total = parseInt(user1VotesCount, 10) + parseInt(user2VotesCount, 10);
  if(0 === total){
    return 50;
  }

  return Math.floor(parseInt(user1VotesCount, 10) * 100 / total);
}
