import React from 'react';
import './ViewPagerPhoto.css';
import { Carousel } from 'react-responsive-carousel';
import { cdnMaker } from '../helper/CDNHelper';

class ViewPagerPhoto extends React.Component {

  render() {
    const{contentUrls} = this.props;
    var contentUrlsArray = JSON.parse(contentUrls);
    let size = contentUrlsArray.length;
    // let statusFormatter = (current, total) => {current} of {total};
    return (
      <Carousel showArrows={false} showThumbs={false} emulateTouch showStatus={size > 1 ? true : false} showIndicators={size > 1 ? true : false}
      statusFormatter={(current, total) => `${current} / ${total}`}>
        {contentUrlsArray.map((contentUrl, index) => (
          <img className="cellImage" key={index} tag="img" src={cdnMaker(contentUrl)} />
        ))}
      </Carousel>
    )
  }
}
export default ViewPagerPhoto;
