import React from 'react';
import './PopupSubmit.css';
import Spinner from '../Spinner';

class PopupSubmit extends React.Component{
  render(){

      return(
        <div className='popup_outer'>
          <div className='popup_inner'>
            <div className='contentPopup'>
              <h1 id='titleLogin'>Submitting</h1>

              <div className='logInSpinner'>
                <Spinner/>
              </div>

            </div>
          </div>
        </div>
      );
  }
}



export default PopupSubmit;
