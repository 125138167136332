import React from 'react';
import './ControlCell.css';
import oneVideo from '../images/ic_type_video.png';
import imgNegative from '../images/ic_musk_selected.png';
import multiPhotos from '../images/ic_multi_photos.png';
import icChallenge from '../images/icon_voted_challenge.png';
import icStarCircle from '../images/ico_star_circle.png';
import icSelected from '../images/ic_selected.png';
import icUnselect from '../images/ic_unselect.png';
import icStar from '../images/ico_star.png';
import Spinner from '../Spinner';
import { cdnMaker } from '../helper/CDNHelper';

class ControlCell extends React.Component {

  componentDidMount(){
    window.addEventListener('scroll', this.onScroll, false);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.onScroll, false);
  }

  onScroll = () => {
    if(
      (window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 500) &&
      this.props.timeline.length &&
      !this.props.isLoading &&
      !this.props.isError &&
      this.props.hasMore
    ){
      this.props.onPaginatedSearch();
    }
  }

  render(){
    const {timeline, isLoading, page, isError, onPaginatedSearch, hasMore, selectCell, badPosts} = this.props;
    if (null === timeline) {
      return <div></div>
    }

    const battleCell = timeline.map((item, index) =>

      <div key={item.id} className="battlecell-ControlRoom"  onClick={() => selectCell(item.id, index)}>

        <div className='gifImageSmall'>
          <img className="gifImage-ControlRoom"  src={item.type === 'ov' ? cdnMaker(item.thumbnailUrl) : cdnMaker(JSON.parse(item.contentUrls)[0])} alt=""></img>

          {
            item.type === 'ov' &&
            <img id='typeVideo' src={oneVideo} alt=''></img>
          }

          {
            item.type === 'mp' &&
            <img id='typePhoto' src={multiPhotos} alt=''></img>
          }

          {
            badPosts.indexOf(item.id) !== -1 &&
            <img className='typeNegative' src={imgNegative} alt=''></img>
          }
        </div>

        <img className='imageSelect' src={badPosts.indexOf(item.id) !== -1 ? icSelected : icUnselect}></img>

      </div>

    )

    return (
      <div>
        <div className='grid-ControlRoom'>
          {battleCell}
        </div>

        <div className='interactions'>
          {isLoading && <Spinner/>}
        </div>

        <div className='interactions'>
          {
            (page !== null && !isLoading && isError && hasMore) &&
            <div>
              <div>
                something went wrong...
              </div>
              <button
                type='button'
                onClick={onPaginatedSearch}
                >
                  Try Again
                </button>
            </div>
          }
        </div>

      </div>

    );
  }

}

export default ControlCell;
