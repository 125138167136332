const BASE_URL_HTTPS = "https://social-club.s3.us-west-2.amazonaws.com/";
const BASE_URL_HTTP = "http://social-club.s3.us-west-2.amazonaws.com/";
// const BASE_URL_HTTPS_2 = "https://s3-us-west-2.amazonaws.com/social-club";
// const BASE_URL_HTTP_2 = "http://s3-us-west-2.amazonaws.com/social-club";
const BASE_HTTP_PREFIX = "http:";
const BASE_HTTPS_PREFIX = "https:";
const BASE_URL_CDN = "http://cdn.socialclub.life/";
const BASE_URL_CDN_HTTPS = "https://cdn.socialclub.life/";

export const cdnMaker = (url)=> {
  if(url !== null){
    if(!url.startsWith(BASE_HTTP_PREFIX) && !url.startsWith(BASE_HTTPS_PREFIX)){
        return BASE_URL_CDN + url;
    }

    if(url.startsWith(BASE_URL_HTTPS)){
      return BASE_URL_CDN + url.substring(BASE_URL_HTTPS.length);
    }

    if(url.startsWith(BASE_URL_HTTP)){
      return BASE_URL_CDN + url.substring(BASE_URL_HTTP.length);
    }

    // if(url.startsWith(BASE_URL_HTTPS_2)){
    //   return BASE_URL_CDN + url.substring(BASE_URL_HTTPS.length);
    // }
    //
    // if(url.startsWith(BASE_URL_HTTP_2)){
    //   return BASE_URL_CDN + url.substring(BASE_URL_HTTP.length);
    // }

    if(url.startsWith(BASE_URL_CDN_HTTPS)){
      return BASE_URL_CDN + url.substring(BASE_URL_CDN_HTTPS.length);
    }
  }

  return url;
}
