import React from 'react';
import './Profile.css';
import { NumberFormatter } from '../helper/NumberFormatter';
import icTimeLine from '../images/ico_time_line_selected.png';
import icFavorite from '../images/ico_favorite_selected.png';
import icTimeLineUnselected from '../images/ico_time_line.png';
import icFavoriteUnselected from '../images/ico_favorite.png';
import btnOpen from '../images/btn_open.png';
import { cdnMaker } from '../helper/CDNHelper';

class Profile extends React.Component {

  showTimeline = () => {
    if(this.props.isTimeline){
      return;
    }
    this.props.setIsTimeline(true);
  }

  showFavorite = () => {
    if(this.props.isTimeline){
      this.props.setIsTimeline(false);
    }
  }

  render() {
    // var imgUrl = 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1529731662474&di=fa3c17826a726b90370e283bd321a701&imgtype=jpg&src=http%3A%2F%2Fimg1.imgtn.bdimg.com%2Fit%2Fu%3D760179301%2C1904675155%26fm%3D214%26gp%3D0.jpg';
    const {user, isTimeline} = this.props;
    // const {imgUrl, userName, viewsNum, followerNum, followingNum} = this.props;
    if(null === user){
      return(<div/>);
    }
    const deepLink = 'http://social-club.app.link?u=' + user.username;
    // const deepLink = "";
    return (<div className='containerProfile'>
      <section>
        <div id='containerProfile'>
          <div id='avatar'>
            <img id='imageAvatar' src={cdnMaker(user.profilePictureUrl)} alt=''/>
          </div>

          <div id='sideTexts'>
            <div id='userName'>
              <span>{user.username}</span> <div id='spanName'>{ user.name}</div>
            </div>

            <div id='numTexts'>
              <div className='profileNumers'>
                <p className='numbersDetail'>{NumberFormatter(user.postsCount)}</p>
                <p className='lightgrey'>Posts</p>
              </div>

              <div id='follower' className='profileNumers'>
                <p className='numbersDetail'>{NumberFormatter(user.followersCount)}</p>
                <p className='lightgrey'>Followers</p>
              </div>

              <div id='following' className='profileNumers'>
                <p className='numbersDetail'>{NumberFormatter(user.followingsCount)}</p>
                <p className='lightgrey'>Following</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id='openButton'>
        <a id='link' href={deepLink}>
          <img id='imageBtnOpen' src={btnOpen} alt=''/>
        </a>
      </div>

      <div id="bioContainer">
        <p id='bioText'>{user.bio}</p>
      </div>

      <div id="battleTypeSwitch">
        <div id="timeline" onClick={this.showTimeline}>
          <img id='imageTimeline' src={isTimeline ? icTimeLine : icTimeLineUnselected} alt=''/>
        </div>
        <div id='divider'/>
        <div id="favorite" onClick={this.showFavorite}>
          <img id='imageFavorite' src={isTimeline ? icFavoriteUnselected : icFavorite} alt=''/>
        </div>
      </div>

    </div>);
  }
}

export default Profile;
