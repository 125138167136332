import React from 'react';
import './App.css';
import Timeline from './Timeline';
import Banner from './Banner';
import Spinner from './Spinner';
import axios from 'axios';

const base_url = '/api/';
const version = 'v1/';
const starred = 'users/get-starred-timeline';

// const secret = 'secret=mingyuanwang-web-201711';

const applySetError = (prevState) => ({
  isError: true,
  isLoading: false,
});

const applyUpdateResult = (is404, result, page, hasMore) => (prevState) => ({
  timeline:[...prevState.timeline, ...result],
  page: page,
  isError: false,
  isLoading: false,
  hasMore: hasMore,
  is404: is404,
});

const applySetResult = (is404, result, page, hasMore) => (prevState) => ({
  timeline:result,
  page:page,
  isError: false,
  isLoading: false,
  hasMore: hasMore,
  is404: is404,
});

class StarredBattle extends React.Component{
  constructor(prop) {
      super(prop);

      this.state = {
          userName: null,
          timeline: null,
          perpage: 0,
          page: 1,
          isLoading: false,
          isError: false,
          hasMore: false,
          is404: false,
      };

      this.fetchStarredBattle.bind(this);
  }

  componentDidMount() {
    const userName = this.props.params.userName;
    this.setState({userName});
    this.fetchStarredBattle(userName, 1);
  }

  fetchStarredBattle(userName, page){
    this.setState({isLoading: true});

    const url = `${base_url}${version}${starred}?username=${userName}&page=${page}`;
    axios.get(url)
    .then(response => {
      var result = response.data;
      this.onSetResult(false, result.data, page, result.data !== null && (result.data.length === result.perPage))
    })
    .catch(error => {
      if(null !== error && 'undefined' !== typeof(error)  && null !== error.response && 'undefined' !== typeof(error.response)){
        if(404 === error.response.status){
          this.setState({is404: true});
        }
      }
      this.onSetError();
    })
  }

  onPaginatedSearch = () =>{
    this.fetchStarredBattle(this.state.userName, this.state.page + 1);
  }

  onSetError = () =>
    this.setState(applySetError);

  onSetResult = (is404, result, page, hasMore) =>
    page === 1
    ? this.setState(applySetResult(is404, result, page, hasMore))
    : this.setState(applyUpdateResult(is404, result, page, hasMore));

  render(){
    const {is404, timeline, isError, isLoading, page, hasMore} = this.state;

    if(is404){
      return(
        <div id='div404'>
          <Banner/>
          <div className='span404'>Oops, Page Not</div>
          <div className='span404'>Found! 😑</div>
        </div>
      );
    }

    const TimelineComponent = this.state.timeline != null ?
        <Timeline
          timeline={timeline}
          isError={isError}
          isLoading={isLoading}
          page={page}
          hasMore={hasMore}
          onPaginatedSearch={this.onPaginatedSearch}
        />
      :
      <div id='divSpinnerBio'>
        <Spinner/>
      </div>
    return(
      <div className='container'>
        <Banner/>
        <div id='timelineContainer'>
          {TimelineComponent}
        </div>
      </div>
    );
  }
}

export default StarredBattle;
