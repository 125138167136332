import React from 'react';
import './BattleHeader.css';
import vsIcon from '../images/ico_versus.png';
import icBattle from '../images/ico_battle.png';
import icVoteWeb from '../images/icVoteWeb.png';
import { cdnMaker } from '../helper/CDNHelper';

class BattleHeader extends React.Component{

  render(){
    const {post} = this.props;
    if(null == post){
      return <div></div>;
    }

    const {user} = post;

    return(
      <div>
        <div id='headerContainer'>
          <div id='leftCell'>
            <a className='link' href={`/${user.username}`}>
              <div id='divLeftUser'>
                <img id='leftAvatar' className='avatarHeader' src={cdnMaker(user.profilePictureUrl)} alt=''/>
                <span id='userNameLeft'>
                  {user.username}
                </span>
              </div>
            </a>
          </div>

          <div id='rightHalf'>

          </div>

        </div>

      </div>
    );
  }
}

export default BattleHeader;
