import React, {Component} from 'react';
import axios from 'axios';
import './App.css';

import BattleHeader from './BattleHeader';
import BattleVideo from './BattleVideo';
import BattleFooter from './BattleFooter';
import Banner from './Banner';
import Spinner from './Spinner';

const base_url = '/api/';
const version = 'v1/';
const post = 'posts/get-info/';
// const secret = 'secret=mingyuanwang-web-201711';

class Battle extends Component {

    constructor(prop) {
        super(prop);

        this.state = {
            post: null,
            is404: false,
        };
        this.fetchPostWithShareId.bind(this);
        this.setPost.bind(this);
    }

    componentDidMount() {
      const shareId = this.props.params.shareId;
      this.fetchPostWithShareId(shareId);
    }

    fetchPostWithShareId(shareId){
      const url = `${base_url}${version}${post}${shareId}`;
      axios.get(url)
      .then(response => {
        var result = response.data;
        this.setPost(result.post, false);
      })
      .catch(error => {
        if(null !== error && 'undefined' !== typeof(error)  && null !== error.response && 'undefined' !== typeof(error.response)){
          if(404 === error.response.status){
            this.setState({is404: true});
          }
        }
      })
    }

    setPost(post, is404){
      this.setState({post: post, is404: is404});
    }

    render() {
        const {is404, post} = this.state;

        if(is404){
          return(
            <div id='div404'>
              <Banner/>
              <div className='span404'>Oops, Page Not</div>
              <div className='span404'>Found! 😑</div>
            </div>
          );
        }

        if(null == post){
          return (
            <div id='divSpinnerBio'>
              <Spinner/>
            </div>
          )
        }

        return (
            <div className='container'>
              <Banner/>
              <BattleHeader post={post}/>
              <BattleVideo post={post}/>
              <BattleFooter post={post}/>
            </div>

        );
    }
}

export default Battle;
